@font-face {
    font-family: "Lucida HandWriting";
    src: url('../Fonts/lucida-handwriting/Lucida\ Handwriting\ Italic.ttf');
}

@font-face {
    font-family: 'Lato';
    src: url('../Fonts/Lato/Lato-Regular.ttf');
}
@font-face {
    font-family: 'Lato';
    src: url('../Fonts/Lato/Lato-Regular.ttf');
}

