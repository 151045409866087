#ph{
    font-family:Lato;
    padding: 0px $PaddingSidesL;
   
}
//#4e6867 with black
//radial-gradient(#7e5760, #cb0000d1) /////00cba6d1

#projects{
    // background: radial-gradient(#7e5760, #cb0000d1); /////00cba6d1
    padding: 40px $PaddingSidesL;
    display: grid;
    grid-template-columns: repeat(3,300px);
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;

    .project{
        // transform: skewY(-5deg) rotateY(deg);
        position: relative;
        width: 400px;
        width: 300px;
        overflow: hidden;
        height: 170px;
        transition: 400ms ease-in;

        img{
            position: absolute;
            top: 0%;
            left: 0%;
            z-index: 1;
            width: inherit;
            height: inherit;
            object-fit: contain;
            transition: 300ms ease-in-out;
        }

        .words{

            
            position: absolute;
            top: 0%;
            left: 0%;
            color: black;
            padding-top: 10px;
            // background:radial-gradient(#68644e, black);
            // background: radial-gradient(#68644ed9, #000000d9);
            background: rgba(100%,100%,100%, 0.60);
            backdrop-filter: blur(5px);
            // border-radius: 16px;
            display: flex;
            flex-direction: column;
            text-align: center;
            z-index: 2;
            width: 304px;
            height: 90px;

            
            transition: 600ms ease-out;
            transform: translateY(225px);
            font-family: Roboto;

            h1{
                font-size: 15px;
            }
            h2{
                margin-top: 5px;
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
            }

        }
    }

    .project:hover{
        
        img{
            transform: scale(1.2);
        }
        .words{
            transform: translateY(100px) translateX(-2px);
            display: flex;
        }
    }
    

    // .project:nth-of-type(even):hover{
    //     box-shadow: 0px 4px 6px rgb(121, 121, 121);
    //     transform: skewX(7deg) skewY(10deg) ;
    // }
    // .project:nth-of-type(odd):hover{
    //     box-shadow: 2px 4px 6px rgb(122, 122, 122);
    //     transform: skewX(-10deg) skewY(-8deg) ;

    //     transform:    skewX(12deg) skewY(-6deg) rotateX(27deg) perspective(150px) rotateY(50deg);
    // }

}



@media screen and (max-width : 980px){
    #projects{
        grid-template-columns: repeat(3,250px);

    }

    
}

@media screen and (max-width : 380px){
    #projects{
        padding: 40px 10px;
        grid-template-columns: repeat(1,250px);
        .project{
            width: 250px;
            height: 146px;


            .words{
                width: 254px;
            }
        }
        
        .project:hover{
            .words{
                transform: translateY(90px) translateX(-2px);
                display: flex;
            }
        }
    }    
}

@media screen and (min-width :381px) and (max-width : 579px){
    #projects{
        padding: 40px 10px;
        grid-template-columns: repeat(1,300px);
        .project{
            width: 300px;
            height: 179px;


            .words{
                width: 304px;
            }
        }
    }    
}

@media screen and (min-width : 580px) and (max-width : 680px){
    #projects{
        padding: 40px 10px;
        grid-template-columns: repeat(2,250px);
        .project{
            width: 250px;
            height: 146px;


            .words{
                width: 254px;
            }
        }
        
        .project:hover{
            .words{
                transform: translateY(90px) translateX(-2px);
                display: flex;
            }
        }
    }    
}


@media screen and (min-width : 681px) and (max-width : 980px){
    #projects{
        padding: 40px 10px;
        grid-template-columns: repeat(2,300px);
        .project{
            width: 300px;
            height: 179px;
            .words{
                width: 304px;
            }
        }    
    }    
}

