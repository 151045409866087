
#contact{
    // background: linear-gradient(305deg , blue ,pink);
    
    padding: 40px 40px;
    display: flex;
    justify-content: center;
    form#conform{
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 59vw;
        h1{
            font-family: Roboto;
            font-style: italic;
        }

        input{    
            margin-bottom: 20px;
            
            font-family: sans-serif;
            font-size: 109%;
            font-weight: 500;
            max-width: 600px;
            outline: none;
            border: none;
            border-bottom: 1px solid peru;
            margin-top: 5px;
            border-radius: 6px;
            padding: 10px;
            border-bottom-left-radius: 0px 5px ;
            border-bottom-right-radius: 0px 20px;
            border-top-right-radius: 50px 40px;
            // box-shadow: inset 2px 3px 6px burlywood;

        }

        // input:first-of-type{
        //     background: $SkyBlue;
        // }
        
        // input:nth-of-type(2){
        //     background: #7bfd8f;
        //     background: #7be5fd;

        // }

        input:nth-of-type(2):-webkit-autofill,
        input:nth-of-type(2):-internal-autofill-previewed,
        input:nth-of-type(2):-internal-autofill-selected {
            box-shadow: 0px 0px 0px rgba(0,0,0,0) inset;
            -webkit-box-shadow: 0 0 0 32px #fda07b inset !important;    
            -webkit-box-shadow: 0 0 0 32px rgb(164, 177, 16) inset !important;    
            -webkit-box-shadow: 0px 20px 0px 32px rgba(16, 177, 155, 0.234) inset !important;    
        }
        input:focus{
            border-bottom: 2px solid peru;
        }
        
        textarea{
            outline: none;
            margin-top: 5px;
            padding: 20px;
            font-size: 107%;
            font-family: Roboto;
            font-style: italic;
            max-width: inherit;
            width: inherit;
            max-height: 300px;
            min-height: 300px;
            border: 1px solid peru;
        }
        
        textarea:focus{
            border-radius: 3px;
            border: 2px solid peru;

        }

        button{
            color: antiquewhite;
            margin-top: 40px;
            align-self: flex-end;
            width: 180px;
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            outline: none;
            border: 2px solid $MildPurple;
            border: 2px solid peru;
            padding: 0.6rem 0rem;
            border-radius: 2rem;
            background: $MildPurple;
            background: peru;
            transition: 200ms ease-in;
            
        }

        button:hover{
            background:rgba(34, 34, 81, 0) ;
            color: black;
            cursor: pointer;
        }
                
        button:disabled{
            background: gray;
            border: 2px solid gray;
            color: rgb(61, 61, 61);
        }
        button:disabled:hover{
            color: rgb(61, 61, 61);
            background: gray;
            border: 2px solid gray;
        }
    }
}


@media screen and (max-width : 520px) {
    #contact{
        form#conform{
            width: 80vw;
        }
    }
    
}