#hh {
  display: flex;
  flex-direction: row;
  background: #4260f8;
  height: fit-content;
  align-content: center;
  padding: 0px 2px;
  width: fit-content;
}

#hero {
  // background: linear-gradient(45deg, #faff11, #91db2a, #faff11);
  background: #faff11;
  padding: 0px 0px 70px 0px;
  position: relative;
  height: 101vh;
  max-height: 800px;
  overflow-x: visible;

  .inh {
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    height: 100%;
  }
  h2 {
    display: flex;
    font-family: Lato, Roboto;
    color: white;
    font-size: 50px;
    font-weight: 200;
    width: fit-content;
    padding: 0px 2px;
  }

  #line {
    width: 1px;
    height: 58px;
    margin-top: 1px;
    background: rgba(0, 0, 0, 0);
    animation: anim 542ms ease-in infinite;
  }

  @keyframes anim {
    50% {
      display: none;
      background: white;
    }

    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  p {
    font-family: Lato;
    font-weight: 600;
    max-width: 400px;
    margin-top: 20px;
    text-indent: 10px;
    text-align: justify;
    font-weight: 400;
    color: #482424ed;
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: 614px) {
  #hero {
    h2 {
      font-size: 40px;
    }
  }
}
@media screen and (max-width: 420px) {
  #hero {
    padding: 0px 0px;
    height: 82vh;

    h2 {
      font-size: 30px;
    }

    .inh {
      padding-left: 20px;
      padding-right: 0px;
    }
    p {
      max-width: 270px;
    }
    #line {
      height: 34px;
    }
  }
  #hi {
    width: 93vw;
    z-index: 1;
  }
}
