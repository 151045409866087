$MildPurple : #9357cf;
$MildGray : #626262;
$MildGrey : #efeff0;
$SkyBlue : #E8F0FE;
$Green : #464b18;
$Salmon : #be621d;
$Yellow : #faff11;
$Blue : #4260F8;
$LightBlue : #007eff;
$Red : #DD0C0C;
$Greenish : #008aa9;
$Wooden : #faeea7;


//Paddings
$PaddingSidesL : 40px;