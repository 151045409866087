#skills{
    padding: 20px $PaddingSidesL;
    margin: 100px 0px;


    h1{
        font-family: Lato;
    }

    #boxs{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        justify-content: center;

        .skill{
            font-family: Roboto;
            margin-top: 60px;
            margin-right: 20px;
            background: $Wooden;
            width: 180px;
            height: 200px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 46px 58px;
            border-bottom-right-radius: 10px 58px;
            border-bottom-left-radius: 10px 58px;
            border-top-left-radius: 20px 38px;

            img{
                max-width: 130px;
            }

            h2{
                font-style: italic;
                margin-top: 10px;
                font-weight: 500;
                font-size: 16px;
                color: rgb(11, 14, 32);
            }

        }
        .skill:hover{
            background: linear-gradient(45deg, $Yellow, transparent);
            cursor: pointer;
            animation: skillanim 1s ease-in ;

        }

        @keyframes skillanim{
            25%{
                transform : translateY(-50px);
                box-shadow: -5px 6px 6px rgb(34, 106, 178);
                // transform: rotateX(20deg);
                // transform: translate(-10px ,-20px ,-30px);
            }
            50%{
                transform : translateY(0px);
            }

            65%{
                box-shadow: -1px 1px 6px peru;
                transform : translateY(-20px);
            }
            100%{
                transform : translateY(0px);
            }   
        }
    
    }
}
