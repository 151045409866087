nav#bn{
    position: relative;
    z-index: 6;
    max-width: 100vw;
    overflow: hidden;
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background: $MildGrey;
    background: rgba($MildGrey, 0);
    margin-bottom: 70px;
    
        svg{
            height: 85px;
            width: 150px;
        }

    #links{
        align-self: center;
        display: flex;
        flex-direction: row;
        z-index: 1;

        svg{
            height: 50px;
            width: 30px;
        }
        
    .linkas{
        max-width: 500px;
        overflow: hidden;
        max-height: 800px;
        
        display: flex;
        flex-direction: row;
        align-self: center;
        display: none;
        ul{
            align-self: center;
            list-style: none;
            font-size: 22px;
            font-family: sans-serif;
            li{
                display: inline-block;
                margin-left: 20px;
                
            }
        }
        a{
            font-family: Lato;
            color: #494952;
        }
        a:hover{
            transform: translateY(10px);
            opacity: 0.8;
        }
    }

    #hamburger{
        align-self: center;
        
        .lines{
            margin-top: 6px;
            display: block;
            height: 5px;
            background: #494952;
            width: 45px;
            border-radius: 6px;
            transition-delay: 250ms;

        }
        .lines:first-of-type{
            transform-origin: 16px 36px;
            width: 31px;
        }
        .lines:last-of-type{
            width: 38px;
            transform-origin: 16px -32px;
        }
    }

    #hamburger:hover{
        cursor: pointer;
    }
    }

    #hl:hover{
        cursor: pointer;
        fill-opacity: 0.8;
    }
}

@media screen and (max-width: 712px) {
    nav#bn{
      padding-right: 20px;
      padding-left: 20px;
      overflow: initial;
      margin-bottom: 30px;
    }
    nav #links .linkas {
      position: absolute;
      left: -250px;
      top: -50px;
      max-height: 100vh;
      height: 100vh;
      width: 94vw;
      padding-left: 25px;
      padding-bottom: 25px;
      background: $MildPurple;
      border-bottom-left-radius: 2px;
    }
    nav #links .linkas svg {
        
      fill: white;
    }
    nav #links .linkas ul {
        display: flex;
        flex-direction: column;
        height: inherit;
      padding: 20px;
      justify-content: center;
    }
    nav #links .linkas ul li {
      width: 150px;
      margin-top: 40px;
      display: block;
      border-bottom: 1px solid #9c9c9c;
    }
    nav #links .linkas ul li a {
      color: aliceblue;
    }
  }

  @media screen and (max-width : 420px) {
    nav#bn{
        padding-right: 5.1vw;
        padding-left: 5.1vw;
    }
    
    nav #links .linkas {
        height: 81vh;
    }
  }