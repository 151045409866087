#services{
    margin: 0px;
    max-width: inherit;
    overflow-x: hidden;
    padding-top: 150px; 
    padding-bottom: 200px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    .sb{

        margin-top: 20px;
        margin-right: 20px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        border: 1px solid #e7e7e7;
        border-radius:6px ;
        transition: 210ms ease-in;
        font-family: Roboto;
        height: 375px;
        img{
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius:50% ;
        }

        h1{
            text-align: center;
            margin-top: 40px;
            font-size: 21px;
        }

        p{
            margin-top: 15px;
            text-align: justify;
            line-height: 22px;
        }
    }

    .sb:nth-of-type(2){
        height: 425px;
    }
    .sb:hover{
        box-shadow: 1px 1px 6px 2px rgb(204, 200, 152);
        transform: translateY(-10px);
    }
}

#bf{
    svg{
        width: 155%;
    }
}

#pc{
    svg{
        width: 80%;
    }
}


@media screen and (max-width : 539px) {
    #services{
        margin: 150px 0px;
        margin-bottom: 100px;
        padding: 0px;
        .sb{
            height: fit-content;
            width: 95vw;
            flex-direction: row;
            padding: 50px 20px;
            margin-bottom: 40px;
            margin-right: 0px;
            .tb{
                margin-top: 0px;
                margin-left: 20px;
            }
            h1{
                margin-top: 0px;
            }

            p{
                text-align: center;
            }
        }

        .sb:nth-of-type(2){
            height: 240px;
        }
    }

    
}