@font-face {
  font-family: "Lucida HandWriting";
  src: url("../Fonts/lucida-handwriting/Lucida Handwriting Italic.ttf");
}

@font-face {
  font-family: 'Lato';
  src: url("../Fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: 'Lato';
  src: url("../Fonts/Lato/Lato-Regular.ttf");
}

* {
  padding: 0px;
  margin: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #747474;
  background: rgba(0, 0, 0, 0);
  background: #faff11;
}

::-webkit-scrollbar-thumb {
  background: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, from(#faff11), to(peru));
  background: linear-gradient(-180deg, #faff11, peru);
  height: 30px;
  width: 10px;
  max-width: 200px;
  width: 200px;
  border-radius: 6px;
}

a {
  text-decoration: none;
}

a:active {
  color: peru;
}

textarea {
  resize: none;
}

input:-webkit-autofill,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  box-shadow: initial;
  -webkit-box-shadow: 0 0 0 0px rgba(0, 0, 0, 0) inset !important;
}

.cont-wrap {
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.cont-wrap .cont {
  width: inherit;
  max-width: 1540px;
  overflow-x: hidden;
}

#er button {
  z-index: 3;
  position: absolute;
  right: 20%;
  bottom: 10%;
  padding: 0.6rem 3rem;
  border-radius: 2rem;
  background: #DD0C0C;
  outline: none;
  border: 2px solid #DD0C0C;
  color: white;
  font-weight: 500;
  font-size: 18px;
  -webkit-transition: 200ms ease-in;
  transition: 200ms ease-in;
}

#er button:hover {
  cursor: pointer;
  background: rgba(255, 0, 0, 0.96);
  color: wheat;
}

#er #hi {
  position: absolute;
  width: 45vw;
  left: -15px;
  z-index: 1;
  max-width: 600px;
  bottom: -10px;
}

#er #ha {
  position: absolute;
  top: 150px;
  max-width: 400px;
  height: auto;
  max-height: 400px;
  right: 200px;
}

@media screen and (max-width: 900px) {
  #er #ha {
    width: 20vw;
    right: 119px;
  }
  #er #hi {
    width: 75vw;
  }
}

@media screen and (max-width: 750px) {
  #ha {
    display: none;
  }
}

@-webkit-keyframes translation-animation {
  from {
    opacity: 0;
    -webkit-transform: translateX(-90%);
            transform: translateX(-90%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes translation-animation {
  from {
    opacity: 0;
    -webkit-transform: translateX(-90%);
            transform: translateX(-90%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes translation-animation2 {
  from {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-90%);
            transform: translateX(-90%);
    opacity: 0;
  }
}

@keyframes translation-animation2 {
  from {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-90%);
            transform: translateX(-90%);
    opacity: 0;
  }
}

@-webkit-keyframes translation-animation3 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes translation-animation3 {
  from {
    opacity: 0;
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@-webkit-keyframes translation-animation4 {
  from {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
    opacity: 0;
  }
}

@keyframes translation-animation4 {
  from {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-90%);
            transform: translateY(-90%);
    opacity: 0;
  }
}

@-webkit-keyframes translation-animation5 {
  from {
    opacity: 0;
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes translation-animation5 {
  from {
    opacity: 0;
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes translation-animation6 {
  from {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
    opacity: 0;
  }
}

@keyframes translation-animation6 {
  from {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
    opacity: 0;
  }
}

nav#bn {
  position: relative;
  z-index: 6;
  max-width: 100vw;
  overflow: hidden;
  padding: 10px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: rgba(239, 239, 240, 0);
  margin-bottom: 70px;
}

nav#bn svg {
  height: 85px;
  width: 150px;
}

nav#bn #links {
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  z-index: 1;
}

nav#bn #links svg {
  height: 50px;
  width: 30px;
}

nav#bn #links .linkas {
  max-width: 500px;
  overflow: hidden;
  max-height: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: center;
      align-self: center;
  display: none;
}

nav#bn #links .linkas ul {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  list-style: none;
  font-size: 22px;
  font-family: sans-serif;
}

nav#bn #links .linkas ul li {
  display: inline-block;
  margin-left: 20px;
}

nav#bn #links .linkas a {
  font-family: Lato;
  color: #494952;
}

nav#bn #links .linkas a:hover {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 0.8;
}

nav#bn #links #hamburger {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

nav#bn #links #hamburger .lines {
  margin-top: 6px;
  display: block;
  height: 5px;
  background: #494952;
  width: 45px;
  border-radius: 6px;
  -webkit-transition-delay: 250ms;
          transition-delay: 250ms;
}

nav#bn #links #hamburger .lines:first-of-type {
  -webkit-transform-origin: 16px 36px;
          transform-origin: 16px 36px;
  width: 31px;
}

nav#bn #links #hamburger .lines:last-of-type {
  width: 38px;
  -webkit-transform-origin: 16px -32px;
          transform-origin: 16px -32px;
}

nav#bn #links #hamburger:hover {
  cursor: pointer;
}

nav#bn #hl:hover {
  cursor: pointer;
  fill-opacity: 0.8;
}

@media screen and (max-width: 712px) {
  nav#bn {
    padding-right: 20px;
    padding-left: 20px;
    overflow: initial;
    margin-bottom: 30px;
  }
  nav #links .linkas {
    position: absolute;
    left: -250px;
    top: -50px;
    max-height: 100vh;
    height: 100vh;
    width: 94vw;
    padding-left: 25px;
    padding-bottom: 25px;
    background: #9357cf;
    border-bottom-left-radius: 2px;
  }
  nav #links .linkas svg {
    fill: white;
  }
  nav #links .linkas ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: inherit;
    padding: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  nav #links .linkas ul li {
    width: 150px;
    margin-top: 40px;
    display: block;
    border-bottom: 1px solid #9c9c9c;
  }
  nav #links .linkas ul li a {
    color: aliceblue;
  }
}

@media screen and (max-width: 420px) {
  nav#bn {
    padding-right: 5.1vw;
    padding-left: 5.1vw;
  }
  nav #links .linkas {
    height: 81vh;
  }
}

#hh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background: #4260f8;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -ms-flex-line-pack: center;
      align-content: center;
  padding: 0px 2px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#hero {
  background: #faff11;
  padding: 0px 0px 70px 0px;
  position: relative;
  height: 101vh;
  max-height: 800px;
  overflow-x: visible;
}

#hero .inh {
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
  height: 100%;
}

#hero h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: Lato, Roboto;
  color: white;
  font-size: 50px;
  font-weight: 200;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 2px;
}

#hero #line {
  width: 1px;
  height: 58px;
  margin-top: 1px;
  background: rgba(0, 0, 0, 0);
  -webkit-animation: anim 542ms ease-in infinite;
          animation: anim 542ms ease-in infinite;
}

@-webkit-keyframes anim {
  50% {
    display: none;
    background: white;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes anim {
  50% {
    display: none;
    background: white;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

#hero p {
  font-family: Lato;
  font-weight: 600;
  max-width: 400px;
  margin-top: 20px;
  text-indent: 10px;
  text-align: justify;
  font-weight: 400;
  color: #482424ed;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 614px) {
  #hero h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 420px) {
  #hero {
    padding: 0px 0px;
    height: 82vh;
  }
  #hero h2 {
    font-size: 30px;
  }
  #hero .inh {
    padding-left: 20px;
    padding-right: 0px;
  }
  #hero p {
    max-width: 270px;
  }
  #hero #line {
    height: 34px;
  }
  #hi {
    width: 93vw;
    z-index: 1;
  }
}

#services {
  margin: 0px;
  max-width: inherit;
  overflow-x: hidden;
  padding-top: 150px;
  padding-bottom: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#services .sb {
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 250px;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  -webkit-transition: 210ms ease-in;
  transition: 210ms ease-in;
  font-family: Roboto;
  height: 375px;
}

#services .sb img {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

#services .sb h1 {
  text-align: center;
  margin-top: 40px;
  font-size: 21px;
}

#services .sb p {
  margin-top: 15px;
  text-align: justify;
  line-height: 22px;
}

#services .sb:nth-of-type(2) {
  height: 425px;
}

#services .sb:hover {
  -webkit-box-shadow: 1px 1px 6px 2px #ccc898;
          box-shadow: 1px 1px 6px 2px #ccc898;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#bf svg {
  width: 155%;
}

#pc svg {
  width: 80%;
}

@media screen and (max-width: 539px) {
  #services {
    margin: 150px 0px;
    margin-bottom: 100px;
    padding: 0px;
  }
  #services .sb {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 95vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 50px 20px;
    margin-bottom: 40px;
    margin-right: 0px;
  }
  #services .sb .tb {
    margin-top: 0px;
    margin-left: 20px;
  }
  #services .sb h1 {
    margin-top: 0px;
  }
  #services .sb p {
    text-align: center;
  }
  #services .sb:nth-of-type(2) {
    height: 240px;
  }
}

.certs {
  padding: 10px 40px;
  margin-bottom: 100px;
}

.certs h1 {
  font-family: Lato, sans-serif;
}

.certs .cert-tab {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.certs .cert-tab .left .tab {
  font-family: Lato, sans-serif;
  font-weight: 700;
  background: -webkit-gradient(linear, left top, left bottom, from(#eaeaea), color-stop(#f3f3f3), to(#eaeaea)) #eaeaea;
  background: linear-gradient(#eaeaea, #f3f3f3, #eaeaea) #eaeaea;
  background: rgba(255, 207, 207, 0.431);
  padding: 20px 40px;
  padding: 15px 40px;
  border-radius: 2rem;
  margin-bottom: 15px;
}

.certs .cert-tab .left .tab:hover {
  background: #faff11;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.certs .cert-tab .right {
  padding: 40px 30px;
  padding: 0px 30px;
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
}

.certs .cert-tab .right .cert-wrapper {
  width: 325px;
  min-height: 270px;
  position: relative;
  overflow-x: hidden;
}

.certs .cert-tab .right .cert-wrapper .img-skelton {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  width: 325px;
  height: 240px;
}

.certs .cert-tab .right .cert-wrapper .img-skelton .lin-grd {
  position: absolute;
  top: 0;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(rgba(201, 201, 201, 0.234)), to(rgba(253, 245, 245, 0)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(201, 201, 201, 0.234), rgba(253, 245, 245, 0));
  width: 40%;
  -webkit-animation: loader 1s ease-in infinite forwards;
          animation: loader 1s ease-in infinite forwards;
}

.certs .cert-tab .right .cert-wrapper img {
  width: inherit;
  height: 240px;
  -o-object-fit: cover;
     object-fit: cover;
}

.certs .cert-tab .right .cert-wrapper h3 {
  line-height: unset;
  text-align: center;
  width: inherit;
  padding: 4px 20px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2c2424;
}

.certs .cert-tab .right .cert-wrapper:hover {
  -webkit-box-shadow: 2px 2px 5px 1px rgba(193, 193, 193, 0.792);
          box-shadow: 2px 2px 5px 1px rgba(193, 193, 193, 0.792);
}

.certs .cert-tab h1 {
  font-family: "Lato", sans-serif;
}

@-webkit-keyframes loader {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes loader {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .certs .cert-tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .certs .cert-tab .right {
    padding: 40px 0px;
  }
}

@media screen and (max-width: 750px) {
  .certs .cert-tab {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .certs .cert-tab .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .certs .cert-tab .left .tab {
    font-size: 12px;
  }
  .certs .cert-tab .right {
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;
    padding: 0px;
  }
  .certs .cert-tab .right .cert-wrapper {
    margin-top: 50px;
  }
  .certs .cert-tab .right h3 {
    font-size: 12px;
  }
}

#ph {
  font-family: Lato;
  padding: 0px 40px;
}

#projects {
  padding: 40px 40px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (300px)[3];
      grid-template-columns: repeat(3, 300px);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  row-gap: 10px;
}

#projects .project {
  position: relative;
  width: 400px;
  width: 300px;
  overflow: hidden;
  height: 170px;
  -webkit-transition: 400ms ease-in;
  transition: 400ms ease-in;
}

#projects .project img {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 1;
  width: inherit;
  height: inherit;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

#projects .project .words {
  position: absolute;
  top: 0%;
  left: 0%;
  color: black;
  padding-top: 10px;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  z-index: 2;
  width: 304px;
  height: 90px;
  -webkit-transition: 600ms ease-out;
  transition: 600ms ease-out;
  -webkit-transform: translateY(225px);
          transform: translateY(225px);
  font-family: Roboto;
}

#projects .project .words h1 {
  font-size: 15px;
}

#projects .project .words h2 {
  margin-top: 5px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

#projects .project:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

#projects .project:hover .words {
  -webkit-transform: translateY(100px) translateX(-2px);
          transform: translateY(100px) translateX(-2px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 980px) {
  #projects {
    -ms-grid-columns: (250px)[3];
        grid-template-columns: repeat(3, 250px);
  }
}

@media screen and (max-width: 380px) {
  #projects {
    padding: 40px 10px;
    -ms-grid-columns: (250px)[1];
        grid-template-columns: repeat(1, 250px);
  }
  #projects .project {
    width: 250px;
    height: 146px;
  }
  #projects .project .words {
    width: 254px;
  }
  #projects .project:hover .words {
    -webkit-transform: translateY(90px) translateX(-2px);
            transform: translateY(90px) translateX(-2px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (min-width: 381px) and (max-width: 579px) {
  #projects {
    padding: 40px 10px;
    -ms-grid-columns: (300px)[1];
        grid-template-columns: repeat(1, 300px);
  }
  #projects .project {
    width: 300px;
    height: 179px;
  }
  #projects .project .words {
    width: 304px;
  }
}

@media screen and (min-width: 580px) and (max-width: 680px) {
  #projects {
    padding: 40px 10px;
    -ms-grid-columns: (250px)[2];
        grid-template-columns: repeat(2, 250px);
  }
  #projects .project {
    width: 250px;
    height: 146px;
  }
  #projects .project .words {
    width: 254px;
  }
  #projects .project:hover .words {
    -webkit-transform: translateY(90px) translateX(-2px);
            transform: translateY(90px) translateX(-2px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (min-width: 681px) and (max-width: 980px) {
  #projects {
    padding: 40px 10px;
    -ms-grid-columns: (300px)[2];
        grid-template-columns: repeat(2, 300px);
  }
  #projects .project {
    width: 300px;
    height: 179px;
  }
  #projects .project .words {
    width: 304px;
  }
}

#skills {
  padding: 20px 40px;
  margin: 100px 0px;
}

#skills h1 {
  font-family: Lato;
}

#skills #boxs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#skills #boxs .skill {
  font-family: Roboto;
  margin-top: 60px;
  margin-right: 20px;
  background: #faeea7;
  width: 180px;
  height: 200px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top-right-radius: 46px 58px;
  border-bottom-right-radius: 10px 58px;
  border-bottom-left-radius: 10px 58px;
  border-top-left-radius: 20px 38px;
}

#skills #boxs .skill img {
  max-width: 130px;
}

#skills #boxs .skill h2 {
  font-style: italic;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #0b0e20;
}

#skills #boxs .skill:hover {
  background: linear-gradient(45deg, #faff11, transparent);
  cursor: pointer;
  -webkit-animation: skillanim 1s ease-in;
          animation: skillanim 1s ease-in;
}

@-webkit-keyframes skillanim {
  25% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-box-shadow: -5px 6px 6px #226ab2;
            box-shadow: -5px 6px 6px #226ab2;
  }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  65% {
    -webkit-box-shadow: -1px 1px 6px peru;
            box-shadow: -1px 1px 6px peru;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes skillanim {
  25% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-box-shadow: -5px 6px 6px #226ab2;
            box-shadow: -5px 6px 6px #226ab2;
  }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  65% {
    -webkit-box-shadow: -1px 1px 6px peru;
            box-shadow: -1px 1px 6px peru;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

#contact {
  padding: 40px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#contact form#conform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 500px;
  width: 59vw;
}

#contact form#conform h1 {
  font-family: Roboto;
  font-style: italic;
}

#contact form#conform input {
  margin-bottom: 20px;
  font-family: sans-serif;
  font-size: 109%;
  font-weight: 500;
  max-width: 600px;
  outline: none;
  border: none;
  border-bottom: 1px solid peru;
  margin-top: 5px;
  border-radius: 6px;
  padding: 10px;
  border-bottom-left-radius: 0px 5px;
  border-bottom-right-radius: 0px 20px;
  border-top-right-radius: 50px 40px;
}

#contact form#conform input:nth-of-type(2):-webkit-autofill,
#contact form#conform input:nth-of-type(2):-internal-autofill-previewed,
#contact form#conform input:nth-of-type(2):-internal-autofill-selected {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) inset;
  -webkit-box-shadow: 0 0 0 32px #fda07b inset !important;
  -webkit-box-shadow: 0 0 0 32px #a4b110 inset !important;
  -webkit-box-shadow: 0px 20px 0px 32px rgba(16, 177, 155, 0.234) inset !important;
}

#contact form#conform input:focus {
  border-bottom: 2px solid peru;
}

#contact form#conform textarea {
  outline: none;
  margin-top: 5px;
  padding: 20px;
  font-size: 107%;
  font-family: Roboto;
  font-style: italic;
  max-width: inherit;
  width: inherit;
  max-height: 300px;
  min-height: 300px;
  border: 1px solid peru;
}

#contact form#conform textarea:focus {
  border-radius: 3px;
  border: 2px solid peru;
}

#contact form#conform button {
  color: antiquewhite;
  margin-top: 40px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 180px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  border: 2px solid #9357cf;
  border: 2px solid peru;
  padding: 0.6rem 0rem;
  border-radius: 2rem;
  background: #9357cf;
  background: peru;
  -webkit-transition: 200ms ease-in;
  transition: 200ms ease-in;
}

#contact form#conform button:hover {
  background: rgba(34, 34, 81, 0);
  color: black;
  cursor: pointer;
}

#contact form#conform button:disabled {
  background: gray;
  border: 2px solid gray;
  color: #3d3d3d;
}

#contact form#conform button:disabled:hover {
  color: #3d3d3d;
  background: gray;
  border: 2px solid gray;
}

@media screen and (max-width: 520px) {
  #contact form#conform {
    width: 80vw;
  }
}

.footer-con {
  background: #efeff0;
}

footer {
  padding: 20px 10vw 20px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-family: 'Century Gothic',Vietnam , Barlow;
}

footer #social {
  padding-top: 20px;
}

footer #social a {
  margin-right: 35px;
}

footer #social a svg {
  width: 25px;
}

footer #social a:first-of-type svg {
  fill: #245794;
}

footer #social a:nth-of-type(2) svg {
  fill: #e406a9;
  position: relative;
  top: 2px;
}

footer #social a:nth-of-type(3) {
  fill: #009fdd;
  height: 45px;
  position: relative;
  top: 3px;
}

footer #social a:nth-of-type(4) {
  margin-right: 0px;
}

footer #social a:nth-of-type(4) svg {
  fill: gray;
  position: relative;
  top: -1px;
}

footer #social a svg:hover {
  cursor: pointer;
  fill: brown;
  fill: black;
}

@media screen and (max-width: 670px) {
  footer {
    padding: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  footer #social {
    padding-top: 40px;
  }
}
