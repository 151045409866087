*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    user-select: none;
}

html{
    scroll-behavior: smooth;
}

body{
    max-width: 100vw;
    overflow-x: hidden;
}
::-webkit-scrollbar{
    width: 5px;
}

::-webkit-scrollbar-track{
    background: rgb(116, 116, 116);
    background: rgba(0, 0, 0 ,0);
    background: $Yellow;
}

::-webkit-scrollbar-thumb{
    background: rgb(43, 43, 43);
    background: linear-gradient(-180deg , $Yellow ,peru);
    height: 30px;
    width: 10px;
    max-width: 200px;
    width: 200px;
    border-radius: 6px;
}

a{
    text-decoration: none;
}
a:active{
    color: peru;
}

textarea{
    resize: none;
}


input:-webkit-autofill,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    box-shadow: initial;
    -webkit-box-shadow: 0 0 0 0px rgba(0,0,0,0) inset !important;
    }

.cont-wrap{
    width: 100vw;
    display: flex;
    justify-content: center;
    .cont{
        width: inherit;
        max-width: 1540px;
        overflow-x: hidden;
    }
}
#er{
    button{
        z-index: 3;
        position: absolute;
        right: 20%;
        bottom: 10%;
        padding: 0.6rem 3rem;
        border-radius: 2rem;
        background: $Red;
        outline: none;
        border: 2px solid $Red;
        color: white;
        font-weight: 500;
        font-size: 18px;
        transition: 200ms ease-in;
    }
    button:hover{
        cursor: pointer;
        background: rgba(255, 0, 0, 0.96);
        color: wheat;
        // color: black;
    }

    #hi{
        position : absolute; 
        width:45vw; 
        left:-15px ; 
        z-index: 1;
        max-width: 600px;
        bottom : -10px;}

    #ha{
        position : absolute ;
        top:150px;
        max-width: 400px;
        height:auto;
        max-height: 400px;
        right : 200px;
    }


}


@media screen and (max-width:900px){
    #er{
        #ha{
            width:20vw;
            right: 119px;
        }

        #hi{
            width: 75vw;
        }
    }
    
}

@media screen and (max-width : 750px) {
    #ha{
        display: none;
    }
    
}



@keyframes translation-animation {
    from{
        opacity: 0;
        transform: translateX(-90%);
        
    }
    to{
        opacity: 1;
        transform: translateX(0%);

    }
    
}

@keyframes translation-animation2 {
    from{
        opacity: 1;
        transform: translateX(0%);
        
        
    }
    to{
        transform: translateX(-90%);
        opacity: 0;

    }
    
}



@keyframes translation-animation3 {
    from{
        opacity: 0;
        transform: translateY(-90%);
        
    }
    to{
        opacity: 1;
        transform: translateY(0%);

    }
    
}

@keyframes translation-animation4 {
    from{
        opacity: 1;
        transform: translateY(0%);
        
        
    }
    to{
        transform: translateY(-90%);
        opacity: 0;

    }
    
}

@keyframes translation-animation5 {
    from{
        opacity: 0;
        transform: translateX(90%);
        
    }
    to{
        opacity: 1;
        transform: translateX(0%);

    }
    
}

@keyframes translation-animation6 {
    from{
        opacity: 1;
        transform: translateX(0%);
        
        
    }
    to{
        transform: translateX(90%);
        opacity: 0;

    }
    
}

