.footer-con{
    background: $MildGrey;
}
footer{
    padding: 20px 10vw 20px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: 'Century Gothic',Vietnam , Barlow;
    #social{
        padding-top: 20px;
        a{
            margin-right: 35px;
            svg{

                width: 25px;
            }
        }
        a:first-of-type{
            svg{

                fill: rgb(36, 87, 148);
            }
            
        }

        a:nth-of-type(2){
            svg{

                fill: rgb(228, 6, 169);
                position: relative;
                top: 2px;
            }
        }

        a:nth-of-type(3){

            fill: rgb(0, 159, 221);
            height: 45px;
            position: relative;
            top: 3px;
        }

        a:nth-of-type(4){
            margin-right: 0px;
            svg{
             fill: gray;
             position: relative;
             top: -1px;
            }   
        }

        a{

            svg:hover{
                cursor: pointer;
                fill: brown;
                fill: black;
                
            }
        }
    }
}

@media screen and (max-width: 670px) {
    footer{
        padding: 20px;
        justify-content: center;
        text-align: center;

        #social{
            padding-top: 40px;
        }
    }
    
}