@import "../variables";
.certs {
  padding: 10px 40px;
  margin-bottom: 100px;
  h1 {
    font-family: Lato, sans-serif;
  }
  .cert-tab {
    margin-top: 50px;
    display: flex;
    // background: radial-gradient(rgb(233, 232, 232),rgba(222, 222, 222, 0.794));
    // background: radial-gradient(rgb(233, 232, 232),rgba(255, 255, 255, 0.794));
    .left {
      .tab {
        font-family: Lato, sans-serif;
        font-weight: 700;
        background: linear-gradient(
            rgb(234, 234, 234),
            rgb(243, 243, 243),
            rgb(234, 234, 234)
          )
          rgb(234, 234, 234);
        background: rgba(255, 207, 207, 0.431);
        // background: rgb(255, 207, 207, 43%);
        padding: 20px 40px;
        padding: 15px 40px;
        border-radius: 2rem;
        margin-bottom: 15px;
        // box-shadow: inset 2px 2px 4px 1px rgb(243, 243, 243);

        &:hover {
          background: $Yellow;
          box-shadow: none;
        }
      }
    }

    .right {
      padding: 40px 30px;
      padding: 0px 30px;
      flex-grow: 2;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .cert-wrapper {
        width: 325px;
        min-height: 270px;
        position: relative;
        overflow-x: hidden;

        .img-skelton {
          background-color: white;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          height: 100%;
          width: 325px;
          height: 240px;
          .lin-grd {
            position: absolute;
            top: 0;
            height: 100%;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0),
              rgba(201, 201, 201, 0.234),
              rgba(253, 245, 245, 0)
            );
            width: 40%;

            animation: loader 1s ease-in infinite forwards;
          }
        }
        img {
          width: inherit;
          height: 240px;
          object-fit: cover;
        }
        h3 {
          line-height: unset;
          text-align: center;
          width: inherit;
          padding: 4px 20px;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-weight: 600;
          color: rgb(44, 36, 36);
        }

        &:hover {
          box-shadow: 2px 2px 5px 1px rgba(193, 193, 193, 0.792);
        }
      }
    }
    h1 {
      font-family: "Lato", sans-serif;
    }
  }
}
@keyframes loader {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .certs {
    .cert-tab {
      display: flex;
      flex-direction: column;
      .right {
        padding: 40px 0px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .certs {
    .cert-tab {
      justify-content: center;
      display: flex;
      flex-direction: column;

      .left {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;

        .tab {
          font-size: 12px;
        }
      }
      .right {
        justify-content: inherit;
        padding: 0px;

        .cert-wrapper {
          margin-top: 50px;
        }

        h3 {
          font-size: 12px;
        }
      }
    }
  }
}
